/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "en",
      "3166-1": "GB",
      "IETF": "en-GB",
    },
    "CURRENCY": "EUR",
    "CURRENCY_SHORT": "$",
    "POINTS": "points",
    "TYPEKIT_ID": "thm8nca",
  },
  "COOKIE_CONSENT": {
    "NOTICE": "To improve and customize your experience of our digital services, we use cookies. By continuing to use the website, you agree to the use of cookies.",
    "ALL": "Accept all",
    "NECESSARY": "Accept necessary",
    "SELECTED": "Accept selection",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Necessary",
    "STATISTICAL": "Statistical",
    "MARKETING": "Marketing",
  },
  "IE_NOTICE": "Du använder en webbläsare som vi inte har stöd för. Siten har begränsad funktionalitet om du använder denna webbläsaren.",
  "COMMON": {
    "SAVE_AS_PDF": "Save as PDF",
    "PRINT": "Print",
    "CLOSE": "Close",
    "CANCEL": "Cancel",
    "SEND": "Send",
    "APPROVE": "Approve",
    "READ_MORE": "Read more",
    "FROM": "From",
    "TO": "To",
    "SEE_ALL": "View all",
    "REMOVE": "Remove",
    "EDIT": "Edit",
    "ADD": "Add",
    "GO_BACK": "Go back",
    "REGISTER": "Register",
    "I_ACCEPT_THE": "I accept",
    "ORDER_ID": "Order ID",
  },
  "LOGO": {
    "ALT": "Logo",
    "TITLE": "Go to home page",
  },
  "HOMEVIEW": {
    "HERO_ALT": "Awardit",
    "POPULAR_BRANDS": "Popular brands",
    "POPULAR_CATEGORIES": "Popular categories",
    "POPULAR_PRODUCTS": "Popular products",
    "SEE_MORE": "View more",
    "BECOME_CUSTOMER": "Create account",
  },
  "ERRORVIEW": {
    "TITLE": "An error has occurred. Please try again again later.",
  },
  "TERMSVIEW": {
    "FALLBACK_TITLE": "Terms",
    "ERROR_FETCHING": "The page you requested could not be fetched. Please try again later.",
  },
  "LOGIN": {
    "TITLE": "Log in",
    "USERNAME": "Username",
    "PASSWORD": "Password",
    "SUBMIT": "Login",
    "FORGOT_PASSWORD": "Forgot your password?",
    "RESET_PASSWORD": "Reset",
  },
  "RESET_PASSWORD": {
    "EMAIL": "Email address",
    "SUBMIT": "Reset password",
  },
  "NEW_PASSWORD": {
    "TITLE": "Enter new password",
    "PASSWORD": "New password",
    "PASSWORD_REPEAT": "Repeat new password",
    "SUBMIT": "Save",
  },
  "REGISTER": {
    "FIRSTNAME": "First name",
    "LASTNAME": "Last name",
    "EMAIL": "Email address",
    "PHONE": "Phone number",
    "COMPANY": "Company",
    "ORG_NUMBER": "Organization number",
    "TOTAL_MEMBERS": "Total employed",
    "STREET_ADDRESS": "Street address",
    "ZIPCODE": "Zipcode",
    "CITY": "City",
    "OTHER": "Other",
    "PNR": "Personal number",
    "GENDER": "Gender",
    "USER_ID": "User ID",
    "SHOP": "Shop",
    "DISTRICT": "District",
    "COUNTRY": "Country",
    "DATE_OF_BIRTH": "Date of birth",
    "PASSWORD": "Password",
    "REPEAT_PASSWORD": "Repeat password",
    "TERMS": "I accept the <a href='%link'>terms of use</a>",
    "EXISITING_ACCOUNT": "Already have an account?",
  },
  "AFFILIATE": {
    "CTA": {
      "ACTIVATE": "Activate",
      "ACTIVATED": "Activated",
      "PROCESSING": "Processing",
      "INACTIVATED": "Inactivated",
    },
  },
  "EARN": {
    "SEARCH": "Search",
    "CLEAR_SEARCH": "Clear",
    "SHOW_LESS": "Show less",
    "SHOW_MORE": "Show more",
    "START": "Start",
  },
  "BRAND": {
    "SEARCH": "Search brands",
    "CLEAR_SEARCH": "Clear search",
    "TOTAL_COUNT": "Showing %total product(s)",
    "EMPTY": "No product available",
  },
  "ACCOUNT": {
    "LOGIN": "Log in",
    "LOGOUT": "Log out",
    "MY_ACCOUNT": "My account",
    "USER": "User",
    "MEMBERSHIP": "Membership",
    "MEMBER_NUMBER": "Membership number",
    "SHIPPING_ADDRESS": "Delivery address",
    "ELAPSED_POINTS": "Expiring %pointsLabel",
    "ELAPSING_POINTS_INFO": "You have %points %pointsLabel that expire %date (%dateRelative)",
    "EDIT_INFO": "Edit information",
    "NO_PHONE_NUMBER": "No phone number",
    "WISHLIST": "Wishlist",
    "EMAIL": "Email",
    "BALANCE": "Balance",
    "EXPIRES": "Expiring",
    "DATE": "Date",
    "SEE_ALL": "View all",
    "EDIT": "Edit",
    "OPEN": "Open",
    "YOUR_POINTS": "Your %pointsLabel",
    "NEWSLETTER": "Subscribe to newsletter",
    "SAVING": "Saving...",
    "SAVE_AND_CLOSE": "Save and close",
    "CURRENT_PASSWORD": "Current password",
    "NEW_PASSWORD": "New password",
    "CONFIRM_NEW_PASSWORD": "Confirm new password",
    "GENDER": {
      "MALE": "Man",
      "FEMALE": "Woman",
      "UNSPECIFIED": "Unspecified",
    },
    "MEMBER_GROUP": {
      "USERS": "Users",
      "DESCRIPTION": "Here you can add, manage, and remove users.",
      "EDIT_MEMBER": "Edit member",
      "ADD_MEMBER": "Add member",
      "SAVE_CHANGES": "Save changes",
      "REMOVE_MEMBER": "Remove member?",
      "REMOVE_MEMBER_CONFIRM": "Are you sure you want to remove %member?",
      "OWNER": "Owner",
      "CAN_LOGIN": "Can log in",
      "FULL_NAME": "Full name",
      "MEMBER_ID": "Member ID",
      "SIGN_INS": "Sign-ins",
      "POINTS": "%pointsLabel",
      "NO_MEMBERS": "The group has no members",
      "TRANSFER_POINTS": {
        "FORMHEADER": "Distribution of %pointsLabel",
        "COMMENT": "Comment",
        "POINTS_AVAILABLE": "%pointsLabel available",
        "POINTS_HANDED_OUT": "%pointsLabel awarded",
        "POINTS_LEFT": "%pointsLabel left",
        "CHOOSE_ALL": "Choose all",
        "HISTORY": {
          "HEADER": "Past dividends",
          "DATE": "Date",
          "RECIPIENTS": "Recipients",
          "DISTRIBUTED": "Awarded",
        },
      },
      "TRANSACTIONS": {
        "DISPLAY": "Display transactions",
        "HEADER": "Transactions and balance",
        "REGISTRATION_DATE": "Registration date:",
        "ACTIVATION_DATE": "Activation date:",
        "CURRENT_BALANCE": "Current balance:",
        "EXPIRING_POINTS": "Number of points that expire next month (%date):",
        "ACCUMULATED_BALANCE": "Accumulated balance:",
        "NO_TRANSACTIONS": "There are no transactions available for this member.",
      },
    },
  },
  "TINK": {
    "ACTIVE": "Aktiv",
    "INACTIVE": "Inactive",
    "REMOVE_PROVIDER": "Remove",
    "RENEW_PROVIDER": "Refresh",
    "REMAINING_DAYS": {
      "SINGULAR": "1 day left",
      "PLURAL": "%days days left",
    },
    "REMOVE_CONFIRM": "Are you sure you'd like to remove the connection to \"%name\"?",
    "CONFIRM": {
      "TITLE": "Remove connection",
      "OK": "Confirm",
      "CANCEL": "Cancel",
    },
    "MODAL": {
      "TITLE": "Renew approval of management of your account",
      "SUBTITLE_PART1": "In order for you to continue to receive %pointsLabel for your purchases, you need to renew the approval for handling your transactions.",
      "SUBTITLE_PART2": "Renew approval of handling your transactions here or do so through",
      "YOUR_ACCOUNT": "your account",
    },
  },
  "POINTS": {
    "HEADER": "Transfer %pointsLabel",
    "MY_POINTS": "My %pointsLabel",
    "INTRO": "You can transfer %pointsLabel between your accounts using the form below.",
    "TRANSFERING": "Transferring...",
    "TRANSFER": "Transfer %pointsLabel",
    "AMOUNT_OF_POINTS": "How many %pointsLabel would you like to transfer?",
    "SELECT_ACCOUNT": "Choose account",
    "FROM_ACCOUNT": "From account",
    "TO_ACCOUNT": "To account",
    "AMOUNT": "Balance",
  },
  "QUIZ_ITEM": {
    "NUM_QUESTIONS": "%questions questions",
    "POINTS": "%points %pointsLabel",
    "STATUS": {
      "CLOSED": "Closed",
      "ANSWERED": "Answered",
      "UNANSWERED": "Unanswered",
    },
    "SHOW_ANSWER": "Show answer",
  },
  "QUIZ": {
    "HEADING": "Question %step of %of",
    "INPUT_LABEL": "Enter your answer",
    "LIST_ALTERNATIV_PLACEHOLDER": "Select Options",
    "IMAGE_INPUT_LABEL": "Upload Image",
    "IMAGE_INPUT_BUTTON": "Select file",
    "NEXT": "Continue",
    "SUBMIT": "Save",
    "ALREADY_ANSWERED": "You have already responded to this survey. Below are your answers.",
    "CLOSED": "The time for this survey has expired. You can no longer respond to it.",
    "SUCCESS": {
      "HEADING": "Thank you for participating!",
      "INFO": "As a thank you, you get %points %pointsLabel to use in our store.",
      "BONUS": "%points %pointsLabel have been added to your balance, the update of the balance will be displayed the next time you log in.",
      "BACK": "To the start page",
      "ALREADY_ANSWERED": "You have already responded to this survey.",
      "COULD_NOT_SAVE": "Could not save your answers. Please make sure all questions are completed or contact support.",
      "QUESTIONNAIRE_CLOSED": "The time for this survey has expired. You can no longer respond to it.",
    },
    "HISTORY_BACK": "Retake the test",
  },
  "QUESTIONNAIRE": {
    "EMPTY_LIST": "Currently, there are no surveys within this category",

  },
  "FILTER": {
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT": "Show result",
      "SHOW_RESULT_TOTAL_MULTIPLE": "Show %total results",
      "SHOW_RESULT_TOTAL_SINGLE": "Show %total result",
      "CLOSE_MENU": "Close filter menu",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Clear all filters",
      "NO_ACTIVE_FILTERS": "You have no active filters",
      "PRICE_RANGE": "Price (%range)",
      "WITHIN_BALANCE": "Products within points balance (%range)",
      "TOTAL_COUNT": "Showing %count results",
      "COUNT": "Active filter",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Filter by price",
      "FILTER_BY_POINTS": "Filter by %pointsLabel",
      "RESET_PRICE": "Clear",
      "PRICE_FROM": "From",
      "PRICE_TO": "To",
      "WITHIN_BALANCE": "Products within points balance",
    },
    "FILTERBAR": {
      "ALL_FILTERS": "All filter",
      "FILTER_BY_PRICE": "Filter by price",
      "FILTER_BY_POINTS": "Filter by %pointsLabel",
      "PRICE_RANGE": "%min - %max %pointsLabel",
      "SORT": "Sort",
      "TOTAL_COUNT": "Showing %total product(s)",
      "SORT_NAME_DOWN": "Name: Z-A",
      "SORT_NAME_UP": "Name: A-Z",
      "SORT_PRICE_DOWN": "Price: ↓",
      "SORT_PRICE_UP": "Price: ↑",
      "SORT_POSITION_DOWN": "Popularity",
      "SORT_POPULAR_DOWN": "Popularity",
      "SORT_VALUE_DOWN": "Value: ↓",
      "SORT_VALUE_UP": "Value: ↑",
      "SORT_RELEASE_DATE_DOWN": "Recently added",
      "SORT_LATEST_DOWN": "Recently added",
      "SORT_RELEVANCE_DOWN": "Relevance",
    },
  },
  "TRANSACTIONS": {
    "TRANSACTION_ID": "Transaction ID",
    "CLUB_OWNER": "Club owner",
    "PRODUCT_ID": "Product ID",
    "QTY": "Quantity",
    "COST": "Cost",
    "MEMBER_ID": "Member ID",
    "MEMBER_NAME": "Member name",
    "DATE": "Date",
    "COMPANY": "Company",
    "EMAIL": "Email",
    "ADDRESS": "Address",
    "POSTCODE": "Postal code",
    "CITY": "City",
    "COUNTRY": "Country",
    "TELEPHONE": "Telephone",
    "ALL_TRANSACTIONS": "All transactions",
    "ONLY_INCOMING": "Only credit transactions",
    "ONLY_OUTGOING": "Only debit transactions",
    "SEARCH": "Search transactions",
    "ID": "ID",
    "TRANSACTION": "Transaction",
    "POINTS": "%pointsLabel",
    "RECEIPT": "Receipt",
    "RECEIPT_DETAILS": "View receipt",
    "POINTS_EXPIRES": "%pointsLabel expire",
    "REFUND": "Refund",
  },
  "REPORTS": {
    "TITLE": "Reports",
    "HISTORY_BUTTON": "History",
    "BACK": "Back",
    "EMPTY": "There are no reports to display",
    "ERROR": "Could not load reports.",
    "HISTORY": {
      "TITLE": "History",
      "SUBTITLE": "Here you see all submitted reports.",
      "TRANSACTIONS": "Transaction History",
      "SEARCH": "Search for form",
    },
    "APPROVAL": {
      "TITLE": "Ready Marking",
      "SUBTITLE_PART1": "Here you clear the orders",
      "SUBTITLE_PART2": "clear mark",
      "SUBMIT": "Done",
    },
    "DOCUMENT": "Document",
  },
  "WISHLIST": {
    "TITLE": "Wishlist",
    "BACK": "Back",
  },
  "NEWS": {
    "TITLE": "News",
    "LATEST": "Latest news",
    "SHOW_ALL": "Show all news",
    "NO_NEWS": "No news to display.",
  },
  "NEWSLETTER_STATUS": {
    "UNREGISTER": {
      "HEADING": "You've been unsubscribed",
      "INFO_1": "You have now been unsubscribed from future newsletters.",
      "INFO_2": "For questions or to report errors, please email:",
      "INFO_3": "If you attach your email address and unsubscribe link, we can help you faster.",
      "SUPPORT_LINK_URL": "mailto:support@awardit.se",
      "SUPPORT_LINK_TITLE": "support@awardit.se",
    },
  },
  "OFFERS": {
    "LINK": "Go to offer",
    "EMPTY": "No offers currently active.",
  },
  "GALLERY": {
    "CLOSE": "Close image gallery",
  },
  "ADDRESS": {
    "FIRSTNAME": "First name",
    "LASTNAME": "Last name",
    "STREET_1": "Street address 1",
    "STREET_2": "Street address 2",
    "POSTCODE": "Postal code",
    "CITY": "City",
    "COUNTRY": "Country",
    "EMAIL": "Email address",
    "TELEPHONE": "Phone number",
    "COMPANY": "Company",
  },
  "LEADERBOARD": {
    "TITLE": "Leaderboard",
    "SUBTITLE_PART1": "Here you can see your ranking according to our conference trip. Good luck",
    "SUBTITLE_PART2": "Your Placement & Summary",
    "POSITION_OF_CONTESTANTS": "of %amount %contestants",
    "MISSING_USER_DATA": "Your user is missing points for the selected period.",
    "MISSING_DATA": "No data available for the selected period.",

    "HERO": {
      "SHOW": "Show",
      "PREVIOUS_MONTH": "Previous Month",
      "MONTH": "Month",
      "YEAR": "Year",
      "YEAR_ALT": "Year",
    },
  },
  "VALUE": "Value",
  "CART": {
    "TITLE": "Cart",
    "EMPTY": "The cart is currently empty",
    "START_SHOPPING": "Stat shopping",
    "CTA": "Go to checkout",
    "ALT": "Edit cart contents",
    "EDIT": "Edit cart contents",
    "TO_PAY": "Grand total",
    "INCREMENT": "Increase quantity",
    "DECREMENT": "Decrease quantity",
    "QUANTITY": "Quantity",
    "OPEN_CART": "Open cart",
    "CLOSE": "Close",
    "CLOSE_CART": "Close checkout",
    "CONTINUE_SHOPPING": "Continue shopping",
    "PRODUCTS": "Products",
    "SHIPPING": "Shipping",
    "SUBTOTAL": "Subtotal",
    "TOTAL": "Total",
    "GRANDTOTAL": "Grand total",
    "YOUR_BALANCE": "Your balance",
    "SUMMARY": "Summary",
    "MINIMIZE_SUMMARY": "Minimize summary",
    "VIEW_DETAILS": "Show summary",
    "TO_CHECKOUT": "Go to checkout",
    "MOVE_TO_WISHLIST": "Move to wishlist",
    "REMOVE_PRODUCT": "Remove product",
    "INSUFFICIENT_FUNDS_HEADER_POSITIVE": "Order grand total below threshold",
    "INSUFFICIENT_FUNDS_HEADER_NEGATIVE": "Order grand total above threshold",
    "INSUFFICIENT_FUNDS_BODY": "All purchases have to be paid with at least %spendingLimit% of your %pointsLabel. If your cart contains products that exceed this condition, you'll have to remove items from the cart in order to proceed.",
    "INSUFFICIENT_FUNDS_POINTS_ONLY_BODY": "Your cart value is higher than your %pointsLabel balance. One or more of your products cannot be paid with split payment. You must remove one or more of your products from your cart to complete the purchase.",
    "INSUFFICIENT_FUNDS_BOTTOM_LABEL": "You are missing %points",
    "INSUFFICIENT_FUNDS_SPECIFIC_PARTNER": "The products in your cart can only be purchased with points earned at %partnerName.",
    "INSUFFICIENT_FUNDS_SUMMARY": "Grand total limit exceeded by",
  },
  "CHECKOUT": {
    "TITLE": "Checkout",
    "NEED_HELP": "Need help? We're here for you!",
    "CONTACT_CUSTOMER_SERVICE": "Contact customer service",
    "TO_PAYMENT": "Proceed to payment",
    "CHOOSE_PAYMENT_METHOD": "Proceed to payment",
    "POINTS_TITLE": "Pay using %pointsLabel",
    "POINTS_BALANCE": "You have %points. Use the slider to select the amount of %pointsLabel you would like to redeem. The remainder (up to %currencyLimit% of the order grand total) can be paid using your card.",
    "POINTS_BALANCE_JAVA": "You have %points. %javatext",
    "AMOUNT_TO_PAY": "To pay",
    "POINTS": "%pointsLabel",
    "PAY_WITH_CARD": "Pay using card",
    "EDIT": "Edit",
    "PAYMENT_AND_DELIVERY": "Payment & Delivery",
    "DISTRIBUTION": "Distribution",
    "SENT_TO": "Sent to",
    "ADDRESSES_TITLE": "Delivery address",
    "EDIT_ADDRESS": "Edit address",
    "ADDRESS_MINIMIZE": "Minimize",
    "CONFIRM_ORDER": "Place order",
    "CONTINUE_SHOPPING": "Continue shopping",
    "PAYMENT_TITLE": "Payment",
    "CARD_PAYMENT": "Card payment",
    "I_ACCEPT_THE": "I accept",
    "TERMS": "the terms",
    "A_TERMS": "Terms & Conditions",
    "ACCORDION_HEADER_CART": "Cart",
    "EX_TAX": "Excl. VAT",
    "INC_TAX": "Incl. VAT",
    "EX_TAX_FULL": "Excl. VAT",
    "INC_TAX_FULL": "Inc. VAT",
    "SUBTOTAL": "Delsumma",
    "PAYMENT_DISTRIBUTION": "Payment distribution",
    "AND": "and",
    "OR": "or",
    "MODE_OF_DELIVERY": "Delivery method",
    "VIRTUAL_DELIVERY": "Your order will be delivered digitally to your email address.",
    "ADDRESS": {
      "ADDRESS": "Address",
      "SHIPPING": "Delivery address",
      "BILLING": "Billing address",
      "SHIP_TO_SAME_ADDRESS": "Use same address on receipt",
      "EMPTY_ADDRESS": "The address is not complete, please update",
      "CONTACT_INFO": "Contact info",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Cart",
        "LONG": "Cart",
      },
      "SHIPPING": {
        "SHORT": "Delivery",
        "LONG": "Confirm your address",
      },
      "PAYMENT": {
        "SHORT": "Payment distribution",
        "LONG": "Distribute your payment",
      },
      "OVERVIEW": {
        "SHORT": "Summary",
        "LONG": "Summary",
      },
    },
  },
  "PAYMENT": {
    "TITLE": "How would you like to pay?",
    "USE_PAY": "Pay now",
    "USE_CARD": "Pay using a card",
    "USE_SAVED_CARD": "Pay using a saved card",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "SUCCESS": {
    "TITLE": "Order confirmed",
    "SUB_TITLE": "Order id %number",
    "QTY": "Quantity: %qty",
    "STEPS": {
      "TITLE": "Thank you for your order!",
      "HEADING": "Order confirmed",
      "WHAT_NOW": "What's next?",
      "LABEL": "Your order",
      "ORDER_ID": "Order id %id",
      "QUESTIONS": {
        "HEADING": "Got a question?",
        "LABEL": "Contact customer service",
        "URL": "/faq",
      },
      "CONFIRMATION": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "Thank you for your order! An order confirmation will be sent to the provided email address: %email shortly. If you do not receive the email, please check if it has ended up in your spam folder.",
        },
        "2": {
          "HEADING": "Delivery",
          "TEXT": "The delivery of your order will be from our own warehouse or from one of our partners. This means that the order may be delivered in multiple shipments. For deliveries from our own warehouse, the order will be sent when all products are in stock. Orders for products from our own warehouse are normally delivered within 3-7 business days. For deliveries from one of our partners, the order will be sent directly from their warehouse. Orders for products from our partners are normally delivered within 1-2 weeks.<br/><br/>In the order confirmation that will be sent to your email address shortly, you will see how your order will be delivered.<br/><br/>Digital products will be sent directly to the email address you provided in the order.",
        },
      },
    },
  },
  "CUSTOMER_SERVICE": {
    "LINK": "/",
  },
  "OLD_BROWSER": {
    "INFO": "Hi! You are using an outdated browser. Please. <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">update your browser</a> to be able to use this site.",
  },
  "NO_JAVASCRIPT": {
    "LOGIN": "You need to enable Javascript to log in.",
  },
  "CATEGORY": {
    "TOTAL_COUNT": "Viewing %total product(s)",
    "EMPTY": "No products to display",
    "LOAD_MORE": "Load more",
    "PRODUCTS_IN_CATEGORY_TITLE": "Viewing %itemsCount products in",
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Viewing 1 product in",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Viewing %itemsCount products in",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Viewing products in",
  },
  "404": {
    "TITLE": "Sorry, but this page could not be found",
    "BACK": "Would you like to go back to the",
    "BACK_LINK": "home page",
  },
  "SERVER_ERROR": {
    "TITLE": "Server error",
    "TEXT": "<strong>Need to contact us?</strong><br/> Send an email to <a href=\"mailto:info@awardit.com\">info@awardit.com</a>",
  },
  "ADMINISTRATION": {
    "APPROVE_MEMBERS": "Approve members",
    "TITLE": "Administration",
  },
  "SEARCH": {
    "AND_ACTIVE_FILTERS": "and active filters",
    "TITLE_PREFIX": "Search: ",
    "PLACEHOLDER": "What are you looking for?",
    "ERROR_MINIMUM_CHARACTERS": "Use at least %characters characters in your query",
    "EMPTY_SEARCH_RESULT": "Found no products that matched the query",
    "PRODUCT_IN_SEARCH_RESULT": "Found 1 product",
    "PRODUCTS_IN_SEARCH_RESULT": "Found %itemsCount products",
    "SEARCHING_FOR": "Searching for",
    "TYPE": {
      "CATEGORY": "Category",
      "BRAND": "Brand",
    },
  },
  "HEADER": {
    "CLOSE_MENU": "Close menu",
    "SEARCH": "Search",
    "CATEGORIES": "Categories",
    "CLOSE_CATEGORIES": "Close categories",
    "AFFILIATE_CATEGORIES": "Partners",
    "BRANDS": "Brands",
    "EARN": "Earn %pointsLabel",
    "REDEEM": "Redeem your %pointsLabel",
    "CLOSE_BRANDS": "Close brands menu",
    "WISHLIST": "Wishlist",
    "ACCOUNT": "My account",
    "CHECKOUT": "Checkout",
    "LOGOUT": "Log out",
    "DELIVER_TO": "Delivered to",
    "HOW_DOES_IT_WORK": "How does it work?",
    "MY_ACCOUNT": "My account",
    "CUSTOMER_SERVICE": "Customer service",
    "OUR_OFFER": "Our offer",
    "NEWS": "News",
    "CATEGORY_SEE_ALL": "View everything in %categoryName",
    "QUESTIONNAIRES": "Questionnaires",
    "OFFER_LIST": "Offers",
    "LEADS": "Leads",
    "FAQ": "Frequently asked questions",
  },
  "FOOTER": {
    "CATEGORIES": "Categories",
    "LINKS": {
      "TITLE": "Links",
      "START": "Home",
      "CATEGORIES": "Categories",
      "BRANDS": "Brands",
      "SEARCH": "Search",
      "WISHLIST": "Wishlist",
      "MY_ACCOUNT": "My account",
      "GO_TO_MY_ACCOUNT": "Go to my account",
      "ALL_TRANSACTIONS": "View all transactions",
    },
    "NEED_HELP": {
      "TITLE": "Need help?",
      "CONTACT": "Contact us",
      "DATE": "Business days 10am-noon",
      "COOKIES": "Manage cookies",
    },
    "LEGAL": {
      "PRIVACY_POLICY": "Privacy policy",
      "TERMS": "Terms",
      "FAQ": "Frequently asked questions",
      "PRIVACY_NOTICE": "Privacy Notice",
    },
  },
  "BREADCRUMBS": {
    "HOME": "Home",
    "SEARCH": "Search results for \"%query\"",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Add to cart",
    "OUT_OF_STOCK": "Out of stock",
    "DESCRIPTION": "Product description",
    "OTHERS_ALSO_LIKED": "Customers who viewed this item also viewed",
    "OPTION_DROPDOWN_PLACEHOLDER": "Select",
    "LOGIN_TO_PURCHASE": "Log in to purchase",
    "CHANGE_IMAGE": "Change image",
    "INSUFFICIENT_FUNDS_HEADING": "Split payment",
    "INSUFFICIENT_FUNDS_BODY": "If you don't have enough %pointsLabel to purchase this item, you can use your card in the checkout to pay the remainder (up to %currencyLimit% of the grand total).",
    "INSUFFICIENT_FUNDS_SPECIFIC_PARTNER": "The products in your cart can only be purchased with points earned at %partnerName.",
    "PERQUISITE_VALUE": "%value (perquisite value excl. VAT for calculating taxation)",
    "RESTRICTION_PRODUCT_ADD_TO_CART": "This product can only be purchased without other products in the cart. Do you want to remove the current items in the cart and proceed?",
    "RESTRICTION_PRODUCT_IN_CART": "The product you're adding to the cart cannot be combined with the existing products. Would you like to remove the items in the cart and add this one?",
    "PRODUCTS_CANNOT_BE_COMBINED": "Products cannot be combined",
    "SPECIFIC_POINT_DEFICIT_HEADING": "You do not have enough points to buy this product",
    "SPECIFIC_POINT_DEFICIT_BODY": "This product can only be purchased with points earned at %partner, and you currently lack %points.",
    "ESTIMATED_DELIVERY": {
      "NORMAL": "Delivery within 3-7 working days",
      "VIRTUAL": "Delivered directly with email",
    },
  },
  "STRIPE": {
    "CARD_NUMBER": "Card number",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
    "PROCESSING": "Processing",
  },
  "VALIDATION": {
    "REQUIRED": "*",
    "EMAIL": "Please enter a valid email address.",
    "PHONE": "Please enter a valid mobile phone number.",
    "NUMERIC": "This is a numeric field.",
    "LENGTH_LT": "This field contains too many characters.",
    "POSTCODE": "Please enter a valid postal code",
    "MATCH": "Passwords doesn't match",
    "NUMBER_GT": "Value must be higher",
    "NUMBER_LT": "Value must be less",
  },
  "MESSAGE": {
    "ERROR": "Error",
    "SUCCESS": "Success",
    "INSUFFICIENT_FUNDS": {
      "HEADING": "Your grand total is above the threshold",
      "BODY": "All purchases have to be paid with at least %spendingLimit% of your %pointsLabel. If your cart contains products that exceed this condition, you'll have to remove items from the cart in order to proceed.",
      "POINTS_ONLY_BODY": "Your cart value is higher than your %pointsLabel balance. One or more of your products cannot be paid with split payment. You must remove one or more of your products from your cart to complete the purchase.",
      "BUTTON_LEFT": "Dismiss",
      "BUTTON_RIGHT": "Edit cart contents",
    },
    "MISSING_DETAILS": {
      "HEADING": "Action Required",
      "NUMBER_NOT_FOUND": "Your profile does not have a phone number. Please complete your details.",
      "DOB_NOT_FOUND": "Your profile does not have a date of birth. Please complete your details.",
      "FIRSTNAME_NOT_FOUND": "Your profile does not have a first name. Please complete your details.",
      "LASTNAME_NOT_FOUND": "Your profile does not have a last name. Please complete your details.",
      "EMAIL_NOT_FOUND": "Your profile does not have an email. Please complete your details.",
      "GENDER_NOT_FOUND": "Your profile does not have a gender. Please complete your details.",
      "ORGNR_NOT_FOUND": "Your profile does not have organisation number. Please complete your details.",
      "BUTTON_LEFT": "Manage Later",
      "BUTTON_RIGHT": "Open My Account",
    },
    "BLOCKED": "You cannot currently shop in the store, if you have any questions please contact your contact person.",
  },
  "STATUS_CODE": {
    /* Client */
    "FALLBACK": "An unknown error occured. Please reload the page and try again.",
    "UNKNOWN": "An unknown error occured. Please reload the page and try again.",
    "NETWORK_ERROR": "The network call was canceled. Please verify that you have an active internet connection and try again.",
    "PLACE_ORDER_CATCH": "An unknown error occured. Please try again later.",
    "CHANGE_PASSWORD_ERROR": "An unknown error occured. Please try again later.",
    "CHANGE_PASSWORD_SUCCESS": "Password updated.",
    "MAIL_SENT": "An email has been sent to you.",
    "PASSWORD_UPDATE": "The password was successfully updated.",
    "TRANSFER_POINTS_ERROR": "%pointsLabel transfer failed.",
    "TRANSFER_POINTS_SUCCESS": "%pointsLabel transfer successfully completed.",
    "SEND_ORDER_SUCCESS": "Your report has been sent.",
    "SEND_ORDER_ERROR": "An error occurred. Please try again later.",

    "REMOVE_MEMBER_ERROR": "An error occurred while removing the member.",
    "REMOVE_MEMBER_SUCCESS": "The member has been removed.",
    "ADD_MEMBER_ERROR": "An error occurred while adding the member.",
    "ADD_MEMBER_SUCCESS": "The member has been added.",
    "UPDATE_MEMBER_ERROR": "An error occurred while updating the member.",
    "UPDATE_MEMBER_SUCCESS": "The member has been updated.",
    "GET_TRANSFERABLEMEMBERS_ERROR": "Could not retrieve members, please try again.",
    "TRANSFER_MEMBER_POINTS_ERROR": "Could not transfer points, please try again.",
    "TRANSFER_MEMBER_POINTS_SUCCESS": "Points have been transferred.",

    "errorProductQuantityNotAvailable": "The requested quantity is not currently available.",
    "errorProductNotInStock": "The requested quantity is not currently available.",
    "errorProductMaxQuantity": "The requested quantity is not currently available.",
    "errorProductNotChosenVariant": "Please select %attributeLabel in order to place this item in your cart.",
    "termsNotAccepted": "You must approve the terms and conditions to complete your order.",

    "errorInvalidLogin": "Invalid login details.",
    "errorLoginFailed": "Invalid login details.",
    "errorMissingCustomer": "Customer not found",
    "errorInsufficientawarditPointsForPurchase": "Balance too low",
    "errorBlacklistedEmail": "Email address already in use.",
    "errorInvalidEmailOrPassword": "Invalid login details.",
    "errorResetPassword": "Password could't not be reset.",
    "updateCustomerEmail_errorNotLoggedIn": "You are not logged in. Please reload the page and try again.",
    "updateCustomerEmail_errorInvalidEmail": "This email address is invalid. Please try again later.",
    "updateCustomerEmail_errorEmailExists": "Email address already in use.",
    "QUIZ_ERROR_ANSWERED": "You have already answered this Quiz",
    "SEND_QUIZ_SUCCESS": "Your answer has been sent",
    "SEND_IMAGE_ERROR": "Your photo could not be sent",
    "SEND_IMAGE_SUCCESS": "Your photo has been sent",
    "TEAMPOT_ERROR": "An error occurred, the %pointsLabel were not sent",
    "TEAMPOT_SUCCESS": "The %pointsLabel have been sent",
    "TEAMPOT_OPTION_ERROR": "The activity bar cannot be empty.",
    "TEAMPOT_POINTS_ERROR": "The %pointsLabel field cannot be empty.",
    "TEAMPOT_RANK_ERROR": "The rank field cannot be empty.",
    "TEAMPOT_USERS": "Members",

    "CART_NO_ITEMS": "Cart is currently empty",

    "AFFILIATE": {
      "ACTIVATED": "Offer activated",
      "NOT_ACTIVATED": "Offer could not be activated.",
    },

    "STRIPE_ERROR": {
      "YOUR_CARDS_EXPIRATION_DATE_IS_INCOMPLETE": "The expiration date of your card lacks data.",
    },

    "TINK_CALLBACK": {
      "1": "You've now successfully registered your conscent and you're now eligable to receive %pointsLabel from all your purchases.", // Complete
      "2": "We could not register your consent.",
      "3": "We could not fetch data from your bank.",
      "4": "Your provider returned an error.",
      "5": "We experienced an error fetching your data.",
      "6": "There is something wrong with the link to Tink. Contact support.",
      "7": "You have cancelled the authentication.",
      "8": "Authentication failed.",
      "9": "An internal error at Tink. Contact support.",
      "10": "A temporary error at Tink. Try again later and contact support if the error persists.",
      "UNKNOWN": "We experienced an error fetching your data.",
      "GENERIC_ERROR": "We experienced an error fetching your data.",
    },

    "REGISTER_MEMBER": {
      "SUCCESS_ACTIVATE": "Your account has been activated!",
      "SUCCESS_REG": "Your user details have been registered, please check your email to activate your account.",
    },

    "ITEM_REMOVED_FROM_CART": "%name was removed from the cart as it is no longer in stock.",

    "MEMBERGROUP": {
      "MEMBER_UPDATED": "Group member is now updated.",
      "MEMBER_ADDED": "Group member is now added to the group.",
      "MEMBER_REMOVED": "Group member is now removed from the group.",
    },
  },

  "ADDITIONAL_MEMBER_NUMBERS": {
    "TABLE": {
      "NAME": "Name",
      "NUMBER": "Customer number",
    },
  },

  "MOTIVATION_POT": {
    "MOTIVATION_POT_TITLE": "Team pot",
    "SUB_TITLE": "Here you can hand out %pointsLabel to the employees",
    "USERS_HEADER": "Distribution of %pointsLabel",
    "CHOOSE_ALL": "Select all",
    "POINTS": "%pointsLabel",
    "POINTS_OF_CHOSEN": "%pointsLabel (x %userAmount selected)",
    "POINTS_BALANCE_ERROR": "Unfortunately, your %pointsLabel balance is not enough",
    "COMMENT": "Comment",
    "POINTS_AVAILABLE": "%pointsLabel available",
    "POINTS_HANDED_OUT": "%pointsLabel awarded",
    "POINTS_LEFT_IN_POT": "%pointsLabel left in the pot",
    "POINTS_HEADER": "%pointsLabel pot",
    "PERIOD": "Period",
    "TOTAL_POINTS": "Total %pointsLabel pot",
    "POINTS_LEFT": "%pointsLabel left",
    "PREVIOUS_HANDED_OUT": "Previous dividends",
    "ACTIVITY": "Activity",
    "MEMBER_COUNT": "Number of members",
    "RANK": "Rank",
    "RANK_OF_CHOSEN": "Rank (x %userAmount selected)",
    "DATE": "Date",
    "RECEIVERS": "Receivers",
  },
  "OR": "Or",
  "MEMBERGROUPVIEW": {
    "PARTICIPANT": "Participant",
    "STATUS": "Status",
    "GROUP_MEMBER": "Group member",
    "POINTS": "%pointsLabel",
    "TRANSACTIONS": "View transactions",
    "REMOVE": "Remove",
    "UPDATE": "Update",
    "POINTSOPTION_0": "Member can only view and redeem his own %pointsLabel",
    "POINTSOPTION_1": "Member can only see the group's %pointsLabel, but can redeem their own %pointsLabel if they have any",
    "POINTSOPTION_2": "Member can view and redeem all %pointsLabel",
    "YES": "Yes",
    "NO": "No",
    "REMOVE_TITLE": "Remove group member",
    "REMOVE_CONFIRM": "Do you really want to remove group member \"%member\"?",
    "ADD_MEMBER": "Add new member",
    "ORGNR": "Organization number",
    "LINK": "Admin member group",
  },
};
